<template>
    <div class="relative inline-block group">
        <slot name="dropdown"></slot>
        <div class="absolute hidden w-min h-content right-0 z-10 bg-white shadow rounded group-hover:block overflow-hidden">
            <slot name="dropdown-content"></slot>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style>
.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  right: 0;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  padding: 12px 16px;
  z-index: 1;
}

.dropdown:hover .dropdown-content {
  display: block;
}
</style>