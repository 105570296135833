<template>
  <div class="w-full flex justify-center items-start mb-16">
    <Wrapper class="max-w-lg">
      <div class="w-11/12 md:w-11/12 m-auto">
        <div class="my-5 text-left">
          <h2 class="text-2xl sm:text-3xl text-blue-dark"><b>Iniciar sesión</b></h2>
          <p class="text-gray-400 sm:text-md">Ingrese con los datos suministrados al momento del registro</p>
        </div>
        <LogInForm/>
      </div>
      <div class="w-full mt-5 pt-5 border-t text-sm">
        Para tener una cuenta con nosotros
        <router-link to="/signup" class="block text-blue-dark"><b>Regístrese aquí</b></router-link>
      </div>
    </Wrapper>
  </div>
</template>

<script>
import LogInForm from '@/components/Login/LogInForm'
import BaseDropdown from '@/components/UI/BaseDropdown'
export default {
  components: {
    LogInForm,
    BaseDropdown
  },
  methods: {
    switchLocale() {
      if(this.$i18n.locale === 'en'){
        this.$i18n.locale = 'es'
      }
      else {
        this.$i18n.locale = 'en'
      }
    }
  },
  computed: {
    displayLocale(){
      let locale = 'EN';
      if(this.$i18n.locale === 'es'){
        locale = 'ES'
      } else {
        locale = 'EN' 
      }
      return locale;
    }
  }
}
</script>