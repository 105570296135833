<template>
  <div>
    <a-modal :visible="visible" shape="round" :closable="false" on-ok="handleCancel" centered>
      <template slot="footer">
        <div class="w-full max-w-sm mx-auto pb-5 grid gap-2">
          <a-button key="back" type="danger" shape="round" ghost :block="true" @click="handleCancel">
            CERRAR
          </a-button>
        </div>
      </template>
      <div class="text-center pt-2">
        <h3 class="mb-4 text-2xl text-blue-dark"><b>Importante</b></h3>
        <p>El usuario se encuentra inactivo dentro de la plataforma administrativa.</p>
        <p v-if="group_flag">La inactivación se debe a la inactivación del rol asignado.</p>
      </div>
    </a-modal>
  </div>
</template>
<script>
export default {
  props: {
    visible: Boolean,
    group_flag: { type: Boolean, default: false }
  },
  methods: {
    showModal() {
      this.visible = true;
    },
    handleCancel(e) {
      this.$emit('toggle-modal', false);
    },
  },
};
</script>
