const createAssessment = async (userAction) => {
  const urlCreateAssessment = `${process.env.VUE_APP_URL_CF_RECAPTCHA}/api-recaptcha-servi/check-token`
  const response = await fetch(urlCreateAssessment, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(userAction),
  });

  let responseCreateAssessment = await response.json();
  return responseCreateAssessment;
};

export default {
  createAssessment
};
