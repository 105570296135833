<template>
  <a-form-model layout="vertical" @submit.prevent="login()">
    <a-form-model-item label="Correo electrónico">
      <a-input placeholder="Ingrese su correo electrónico" autocomplete="username" v-model="email" ref='email'/>
    </a-form-model-item>
    <a-form-model-item label="Contraseña">
      <a-input-password placeholder="Ingrese su contraseña" autocomplete="current-password" v-model="password" />
      <div class="mt-2 text-left text-sm flex flex-col justify-center items-center sm:flex-row sm:justify-between">
        <span class="my-1 flex justifycenter items-center text-xs"><input type="checkbox" id="remember" class="mr-2 rounded"> Recordarme</span>
        <router-link to="/recovery" class="my-1 text-blue text-xs"><b>¿Olvidó su contraseña?</b></router-link>
      </div>
    </a-form-model-item>
    <a-form-model-item v-if="feedback">
        <p class="text-sm text-red-500">{{feedback}}</p>
    </a-form-model-item>
    <a-form-model-item v-show="showRecaptchaChallenge">
      <div class="g-recaptcha" :data-sitekey="recaptchaChallengeSiteKey" data-action="LOGIN" data-callback="recaptchaChallengeToken" 
      data-expired-callback="reloadRecaptcha" data-error-callback="reloadRecaptcha" data-close-callback="reloadRecaptcha"></div>            
    </a-form-model-item>
    <a-form-model-item >
      <a-button type="primary" html-type="submit" shape="round" :block="true" :loading="loading" :disabled="!email || !password">
        <span class="mx-2">INICIAR SESIÓN</span>
      </a-button>
    </a-form-model-item>
    <ModalInactivateResponse :visible="showModal" :group_flag="groupFlag" @toggle-modal="toggleModal"/>
    <Modal v-if="showModalRecaptchaEnterprise" size="md">
      <div class="text-center">
        <p class="mt-4" v-html="modalText"></p>
        <button @click="closeModalRecaptcha" class="mt-4 py-2 px-4 bg-blue-500 text-white rounded-lg">ACEPTAR</button>
      </div>
    </Modal>
  </a-form-model>   

</template>
<script>
import ModalInactivateResponse from "./ModalInactivateResponse.vue";
import Modal from "@/components/UI/Modal.vue";
import { ACTION_LOGIN, MSG_ERROR_RECAPTCHA } from '../../core/utils/constants.js';
import recaptchaEnterpriseClient from '../../core/data/network/client/recaptcha_enterprise_client.js';

export default {
  components: {
    Modal,
    ModalInactivateResponse
  },
    data(){
        return {
            email: '',
            password: '',
            feedback: '',
            loading: false,
            modalText: MSG_ERROR_RECAPTCHA,
            showModal: false,
            showModalRecaptchaEnterprise: false,
            groupFlag: false,
            recaptchaChallengeSiteKey: process.env.VUE_APP_SK_RECAPTCHA_CHALLENGE,
            showRecaptchaChallenge: false
        }
    },
    mounted(){            
      this.$refs.email.$el.focus()
      window.recaptchaChallengeToken = this.recaptchaChallengeToken
      window.reloadRecaptcha = this.reloadRecaptcha
    },    
    created(){
      this.configureRecaptcha()
    },
    methods: {
      configureRecaptcha(){
        let recaptchaScript = document.createElement("script");
        recaptchaScript.setAttribute(
          "src",
          `https://www.google.com/recaptcha/enterprise.js?render=${process.env.VUE_APP_SK_RECAPTCHA}`
        );
        document.head.appendChild(recaptchaScript);

        let recaptchaChallengeScript = document.createElement("script");
        recaptchaChallengeScript.setAttribute(
          "src",
          "https://www.google.com/recaptcha/enterprise.js"
        );
        document.head.appendChild(recaptchaChallengeScript);
      },
      async recaptchaChallengeToken(tokenUser) {
        const siteKeyType = 'challenge'        
        const userAction = {
            event: {
              token: tokenUser,             
              expectedAction: ACTION_LOGIN,
              typeSiteKey: siteKeyType
            },
        };
         
        let responseCreateAssessment = await recaptchaEnterpriseClient.createAssessment(userAction);
        if (responseCreateAssessment.data.score < process.env.VUE_APP_MAX_SCORE_CHALLENGE) {
            this.showModalRecaptchaEnterprise = true;
            return;
        }
        this.loginUser(); 
        this.reloadRecaptcha();
      },
      reloadRecaptcha() {         
        this.showModalRecaptchaEnterprise = false
        this.showRecaptchaChallenge = false
        grecaptcha.enterprise.reset()
        this.loading = false;
      },
      closeModalRecaptcha() {
        this.showModalRecaptchaEnterprise = false;   
        this.reloadRecaptcha();      
      },
      async login(){
        this.loading = true;
        grecaptcha.enterprise.ready(async () => {
          const siteKey = process.env.VUE_APP_SK_RECAPTCHA;           
          const siteKeyType = 'score'
          const tokenUser = await grecaptcha.enterprise.execute(`${siteKey}`, {action: `${ACTION_LOGIN}`});
          const userAction = {
            event: {
              token: tokenUser,             
              expectedAction: ACTION_LOGIN,
              typeSiteKey: siteKeyType
            },
          };

         let responseCreateAssessment = await recaptchaEnterpriseClient.createAssessment(userAction);
          if (responseCreateAssessment.data.valid === false) {
            this.showModalRecaptchaEnterprise = true;
            return;
          }
          
          if(responseCreateAssessment.data.score > process.env.VUE_APP_MIN_SCORE_CHALLENGE 
            && responseCreateAssessment.data.score < process.env.VUE_APP_MAX_SCORE_CHALLENGE )
          {
            this.showRecaptchaChallenge = true;
            return;
          }
          
          this.loginUser();
          this.reloadRecaptcha();
     
        });

      },
      async loginUser(){
        this.$store.dispatch('session/login', {email: this.email?.toLowerCase().trim(), password:this.password}).then((res)=>{
          if (res === undefined) {       
            throw 'Error en inicio de sesión';                                           
          }
          this.loading = false;
          if(!this.$store.state.session.user.is_active){
            if (this.$store.state.session.user.id_company?.id === 1) {
              this.groupFlag = !this.$store.state.session.user.group_state;
              this.showModal = true;    
            } else {              
            this.$router.push('/verify');
            }
          } else {
            // Pero el rol se encuentra inactivo igual no se deja iniciar sesión.
            if (this.$store.state.session.user.id_company?.id === 1 && !this.$store.state.session.user?.group_state) {
              this.groupFlag = !this.$store.state.session.user.group_state;
              this.showModal = true;
              return;
            } 
            this.$router.push('/').catch(()=>{});
          }
        })
        .catch(error => {                       
          if (error?.status_code) {
              if (error.status_code === 429) {                
                this.feedback = error.detail;
              }
          } else if (error?.error === 'Los datos no coinciden') {              
            this.feedback = 'Usuario y/o contraseña no son válidos';                                 
          } else if (error?.error) {
              this.feedback = error.error;
          } else {
              this.feedback = 'Error con el servidor, intente más tarde.';
          }          
          this.loading = false;
      });

      },
      checkForm(){
        let validation = false;
        if(!this.$validator.isEmail(this.email) || this.$validator.isEmpty(this.password)){
          this.feedback = 'Verifique la información';
        } else {
          this.feedback = '';
          validation = true;
        }
        return validation;
      },
      toggleModal(value) {
        this.showModal = value;
      }
  }
}
</script>